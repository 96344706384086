import { Link } from "gatsby"
import * as React from "react"

const Footer = () => (
    <footer className="bottom">
        <p>
            Want to <Link to="/sponsor">Sponsor the Podcast?</Link>
        </p>
        <p>&copy; District Bar Talk {new Date().getFullYear()}</p>
        <p>
            Website built and maintained by BH
            <a
                href="https://bhwebdev.com"
                target="_blank"
                rel="noopener noreferrer"
            />
        </p>
    </footer>
)

export default Footer
