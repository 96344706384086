import { Link } from "gatsby"
import * as React from "react"
import Subscribe from "./Subscribe"

const Header = () => (
    <header className="header">
        <div className="header__left">
            <Link to="/">
                <img
                    className="header__logo"
                    src={require("../static/dbt-logo-transparent.png")}
                    alt="District Bar Talk"
                />
            </Link>
        </div>
        <div className="header__right">
            <div className="title">
                <h2 className="tagline">
                    A podcast centered around topics you would hear in your
                    local, SOMD or DC area bar, hosted by 3 normal guys who
                    frequent them...
                </h2>
            </div>
            <Subscribe />
        </div>
        <div className="people">
            <div className="person">
                <img
                    src={require("../static/buster.jpg")}
                    alt="Buster Williams"
                    className="avatar"
                />
                <h3>Buster Williams</h3>
                <a
                    target="_blank"
                    href="https://twitter.com/bwthree"
                    className="person__social person__social--twitter"
                    rel="noopener noreferrer"
                >
                    @bwthree
                </a>
                <p>
                    Born and raised in Southern Maryland, I have followed DC
                    Sports my entire life. Forever greateful to the Caps for
                    finally breaking through and giving us a winner. UMD Alumnus
                    with a degree in Economics, which has been put to use for
                    zero jobs. Married to a beautiful woman, who has given me
                    two amazing daughters.
                </p>
            </div>

            <div className="person">
                <img
                    src={require("../static/jeff.jpg")}
                    alt="Jeff Butler"
                    className="avatar"
                />
                <h3>Jeff Butler</h3>
                <a
                    target="_blank"
                    href="https://twitter.com/Jeff_Butler12"
                    className="person__social person__social--twitter"
                    rel="noopener noreferrer"
                >
                    @Jeff_Butler12
                </a>
                <p>
                    I'm a self-proclaimed "blind-homer" who loves all of his
                    local sports teams, for worse or worser. I've always had a
                    knack for writing, and for having friends with similar
                    interests and big ambitions (i.e. Buster). I paid for a
                    degree in Kinesiology from UMD, which comes in great use for
                    my day job of managing a Roofing company.
                </p>
            </div>
            <div className="person">
                <img
                    src={require("../static/rick.jpg")}
                    alt="Rick Mattingly"
                    className="avatar"
                />
                <h3>Rick Mattingly</h3>
                <p>
                    Self proclaimed master of nothing. Relocated from Baltimore
                    to Southern Maryland and grew up a fan of DC sports. No
                    college education. Out-kicked my coverage by a country mile.
                </p>
            </div>
        </div>
    </header>
)

export default Header
