import * as React from "react"
import SEO from "../components/seo"
import Page from "../components/Page"

type Props = {}

export default (props: Props) => (
    <Page>
        <SEO title="Sponsor District Bar Talk Podcast" />
        <div className="wrapper wrapper--text">
            <h1>District Bar Talk Sponsorship</h1>
            <p>
                Interested in getting your message our audience? We're
                interested in helping! If you think our audience may be a good
                fit, let's chat about how we can make this sponsorship worth it
                for you.
            </p>
            <p>
                We offer packages to fit any business' needs including but not
                limited to single episodes, monthly and annually.
            </p>

            <h2>Contact Us</h2>
            <p>
                Please <a href="mailto:busterw08@gmail.com">send us an email</a>{" "}
                if you are interested in working together and sponsoring the
                podcast.
            </p>
        </div>
    </Page>
)
