import * as React from "react"

type Props = {}

const Subscribe = (props: Props) => (
    <div className="subscribe">
        <ul className="subscribe__links">
            <li className="subscribe__link subscribe__link--itunes">
                <a
                    target="_blank"
                    href="https://itunes.apple.com/us/podcast/district-bar-talk/id1449432564?mt=2"
                    rel="noopener noreferrer"
                >
                    iTunes
                </a>
            </li>
            <li className="subscribe__link subscribe__link--stitcher">
                <a
                    target="_blank"
                    href="https://www.stitcher.com/podcast/district-bar-talk"
                    rel="noopener noreferrer"
                >
                    Stitcher
                </a>
            </li>
            <li className="subscribe__link subscribe__link--googleplay">
                <a
                    target="_blank"
                    href="https://play.google.com/music/listen?u=0#/ps/I4lv3jst5gn2nguqcch5fv5ifpi"
                    rel="noopener noreferrer"
                >
                    Google Play
                </a>
            </li>
            <li className="subscribe__link subscribe__link--spotify">
                <a
                    target="_blank"
                    href="https://open.spotify.com/show/2IWlb5DEytZiHX5QenmV1G"
                    rel="noopener noreferrer"
                >
                    Spotify
                </a>
            </li>
            <li className="subscribe__link subscribe__link--rss">
                <a
                    target="_blank"
                    href="https://feeds.buzzsprout.com/240477.rss"
                    rel="noopener noreferrer"
                >
                    RSS
                </a>
            </li>
        </ul>
    </div>
)

export default Subscribe
