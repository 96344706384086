import PropTypes from "prop-types"
import * as React from "react"
import "../styles/style.styl"
import Footer from "./Footer"
import Header from "./Header"

export default class Page extends React.Component {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ]).isRequired
    }

    render() {
        const { children } = this.props
        return (
            <div className="page">
                <a href="#main" className="skip-link">
                    Skip to content
                </a>
                <Header />
                {children}
                <Footer />
            </div>
        )
    }
}
